import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  P,
  Pagination,
  Popover,
  Table,
  Tag,
} from '@decisiv/ui-components';
import Newspaper from '@decisiv/iconix/lib/components/Newspaper';
import CheckCircle from '@decisiv/iconix/lib/components/CheckCircle';
import CheckCircleF from '@decisiv/iconix/lib/components/CheckCircleF';
import ExclamationTriangle from '@decisiv/iconix/lib/components/ExclamationTriangle';
import ExclamationTriangleF from '@decisiv/iconix/lib/components/ExclamationTriangleF';
import Hourglass from '@decisiv/iconix/lib/components/Hourglass';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { t } from '@lingui/macro';
import ExecutionDetailsModal from '../../components/ExecutionDetails/ExecutionDetailsModal';

export default function LogList({ logsList }) {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [currentEndIndex, setCurrentEndIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [totalLogsPage, setTotalLogsPage] = useState(0);
  const [currentPageLogs, setCurrentPageLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [logs, setLogs] = useState([]);
  const [logModalDetailsVisibility, setLogModalDetailsVisibility] =
    useState(false);
  const [selectedLogDetail, setSelectedLogDetail] = useState();

  const pageChangeHandler = (newPageNumber) => {
    setCurrentPage(newPageNumber);

    // Calculate the starting index and ending index for the specified page
    const startIndex = (newPageNumber - 1) * itemsPerPage;
    setCurrentStartIndex(startIndex);
    const endIndex = startIndex + itemsPerPage;
    setCurrentEndIndex(endIndex);
    // Use slice to get the portion of the array for the specified page
    const pageLogs = logs?.slice(startIndex, endIndex);
    setTotalLogsPage(Math.ceil(logs?.length / itemsPerPage));
    setCurrentPageLogs(pageLogs);
  };

  useEffect(() => {
    setItemsPerPage(25);
    pageChangeHandler(1);
    setLogs(logsList);
  }, [logs]);

  const handleShowModal = (data) => {
    setSelectedLogDetail(data.rowData.attributes);
    setLogModalDetailsVisibility(true);
  };

  const columns = [
    {
      DataCell: (data) => (
        <a
          target="_blank"
          href={`/integrations/${data?.rowData?.attributes?.integration_id}`}
          rel="noreferrer"
        >
          {data?.rowData?.attributes?.integration_name}
        </a>
      ),
      title: t`INTEGRATION`,
      name: 'integration_name',
    },
    {
      DataCell: (data) => (
        <a
          target="_blank"
          href={`/location_settings/${data?.rowData?.attributes?.configured_integration_id}`}
          rel="noreferrer"
        >
          {data?.rowData?.attributes?.location_name}
        </a>
      ),
      title: t`LOCATION`,
      name: 'location_name',
    },
    {
      DataCell: (data) => data?.rowData?.attributes?.x_decisiv_trace_id,
      title: t`X-DECISIV-TRACE-ID`,
      name: 'x_decisiv_trace_id',
    },
    {
      DataCell: (data) => {
        let text;
        let color;
        let icon;

        switch (data.rowData.attributes.status) {
          case 'SUCCEEDED':
            text = 'Succeeded';
            color = 'success';
            icon = CheckCircleF;
            break;
          case 'ExecutionDoesNotExist':
            text = 'Over 90 days ago';
            color = 'danger';
            icon = ExclamationTriangleF;
            break;
          case 'RUNNING':
            text = 'Execution running';
            color = 'warning';
            icon = ExclamationTriangleF;
            break;
          case 'NOT_FOUND':
            color = 'success';
            text = 'Succeded - No Results';
            icon = CheckCircle;
            break;
          case 'FAILED':
          default:
            text = 'Failed';
            color = 'danger';
            icon = ExclamationTriangle;
        }

        let outTag = null;
        if (
          data.rowData.attributes.status &&
          data.rowData.attributes.status !== 'RUNNING'
        ) {
          outTag = (
            <>
              <Flex alignItems="center">
                <Popover
                  placement="bottom-start"
                  manageEvents="hover"
                  target={({ ref, toggle }) => (
                    <Tag
                      ref={ref}
                      color={color}
                      icon={icon}
                      variant="outline"
                      action={toggle}
                      style={{ marginLeft: '15px' }}
                    />
                  )}
                >
                  <Flex padding={1}>
                    <span>{text}</span>
                  </Flex>
                </Popover>
              </Flex>
            </>
          );
        } else if (data.rowData.attributes.status === 'RUNNING') {
          outTag = (
            <>
              <Flex alignItems="center">
                <Popover
                  placement="bottom-start"
                  manageEvents="hover"
                  target={({ ref }) => (
                    <Button
                      icon={Hourglass}
                      ref={ref}
                      variant="ghost"
                      style={{ cursor: 'pointer', marginLeft: '20px' }}
                    />
                  )}
                >
                  <Flex padding={1}>
                    <span>{text}</span>
                  </Flex>
                </Popover>
              </Flex>
            </>
          );
        }

        return outTag;
      },
      title: t`HTTP STATUS`,
      name: 'http_status',
    },
    {
      DataCell: (data) => (
        <>
          <span>
            {data.rowData.attributes.response_from_cache === true
              ? t`Cache`
              : t`Live`}
          </span>
        </>
      ),
      title: t`SOURCE`,
      name: 'source',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.status ||
            data.rowData.attributes.start_date === 'ExecutionDoesNotExist' ? (
              <>
                <span>
                  {data.rowData.attributes.status &&
                    moment(data.rowData.attributes.start_date).format(
                      'MM/DD/YYYY hh:mm:ss a z',
                    )}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      title: t`STARTED`,
      name: 'started',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.status ||
            data.rowData.attributes.stop_date === 'ExecutionDoesNotExist' ? (
              <>
                <span>
                  {data.rowData.attributes.status &&
                    data.rowData.attributes.stop_date &&
                    moment(data.rowData.attributes.stop_date).format(
                      'MM/DD/YYYY hh:mm:ss a z',
                    )}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      title: t`END TIME`,
      name: 'end_time',
    },
    {
      DataCell: (data) => {
        return (
          <>
            <Button
              icon={Newspaper}
              variant="ghost"
              onClick={() => handleShowModal(data)}
              id={`showLogModalBtn-${data.rowData.id}`}
              style={{ cursor: 'pointer' }}
            />
          </>
        );
      },
      name: 'actions',
    },
  ];

  return (
    <>
      <ExecutionDetailsModal
        log={selectedLogDetail}
        visibility={logModalDetailsVisibility}
        handleVisibility={setLogModalDetailsVisibility}
      />

      <Table
        columns={columns}
        data={currentPageLogs}
        kind="secondary"
        getRowKey={({ id }) => id}
        footer={() => (
          <Flex flex={1} alignItems="center" justifyContent="space-between">
            <Flex>
              <P>
                {currentStartIndex + 1} -{' '}
                {itemsPerPage > logs.length ? logs.length : currentEndIndex} of{' '}
                {logs.length} Items
              </P>
            </Flex>
            <Pagination
              totalPages={totalLogsPage}
              onPageChange={pageChangeHandler}
              activePage={currentPage}
            />
          </Flex>
        )}
      />
    </>
  );
}

LogList.defaultProps = {
  logsList: [],
};

LogList.propTypes = {
  logsList: PropTypes.oneOfType([PropTypes.array]),
};
