import React, { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import {
  Button,
  Flex,
  H3,
  P,
  Pagination,
  Popover,
  Table,
  Tag,
} from '@decisiv/ui-components';
import Newspaper from '@decisiv/iconix/lib/components/Newspaper';
import ExternalLink from '@decisiv/iconix/lib/components/ExternalLink';
import Copy from '@decisiv/iconix/lib/components/Copy';
import CheckCircleF from '@decisiv/iconix/lib/components/CheckCircleF';
import CheckCircle from '@decisiv/iconix/lib/components/CheckCircle';
import ExclamationTriangle from '@decisiv/iconix/lib/components/ExclamationTriangle';
import ExclamationTriangleF from '@decisiv/iconix/lib/components/ExclamationTriangleF';
import Hourglass from '@decisiv/iconix/lib/components/Hourglass';
import Refresh from '@decisiv/iconix/lib/components/Refresh';

import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import reverse from 'lodash/reverse';
import moment from 'moment';

import noop from 'lodash/noop';
import ExecutionDetailsModal from '../ExecutionDetails/ExecutionDetailsModal';

export default function LocationExecutionLog({ executionsList, setRefresh }) {
  const [currentPageExecutions, setCurrentPageExecutions] = useState([]);
  const [sortedExecutions, setSortedExecutions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [totalExecutionsPage, setTotalExecutionsPage] = useState(0);
  const [executions, setExecutions] = useState([]);
  const [groupedExecutions, setGroupedExecutions] = useState([]);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [currentEndIndex, setCurrentEndIndex] = useState(0);
  const [logModalDetailsVisibility, setLogModalDetailsVisibility] =
    useState(false);
  const [selectedLogDetail, setSelectedLogDetail] = useState();
  const groupExecutions = (items) => {
    const groupedArrays = [];

    items.forEach((item) => {
      const arn = item.attributes.step_function_arn;

      if (!groupedArrays[arn]) {
        groupedArrays[arn] = [];
      }

      groupedArrays[arn].push(item);
    });

    return Object.values(groupedArrays);
  };

  const pageChangeHandler = (newPageNumber) => {
    setCurrentPage(newPageNumber);
    let currentExecutions = [];

    if (isEmpty(sortedExecutions)) {
      currentExecutions = executions;
    } else {
      currentExecutions = sortedExecutions;
    }
    // Calculate the starting index and ending index for the specified page
    const startIndex = (newPageNumber - 1) * itemsPerPage;
    setCurrentStartIndex(startIndex);
    const endIndex = startIndex + itemsPerPage;
    setCurrentEndIndex(endIndex);
    // Use slice to get the portion of the array for the specified page
    const pageExecutions = currentExecutions?.slice(startIndex, endIndex);
    setTotalExecutionsPage(Math.ceil(currentExecutions?.length / itemsPerPage));
    setCurrentPageExecutions(pageExecutions);
  };

  useEffect(() => {
    const executionsByArn = groupExecutions(executionsList);
    setGroupedExecutions(executionsByArn);
    setExecutions(executionsList);
  }, [executionsList]);

  useEffect(() => {
    setItemsPerPage(5);
    pageChangeHandler(1);
  }, [executions]);

  useEffect(() => {
    pageChangeHandler(1);
  }, [sortedExecutions]);

  const handleShowModal = (data) => {
    setSelectedLogDetail(data.rowData.attributes);
    setLogModalDetailsVisibility(true);
  };

  const onSort = useCallback(
    (columnName) => (sortingType) => {
      switch (sortingType) {
        case 'ascending':
          setSortedExecutions(sortBy(executions, [columnName]));
          return;
        case 'descending':
          setSortedExecutions(reverse(sortBy(executions, [columnName])));
          return;
        case 'unsorted':
        default:
          setSortedExecutions(executions);
      }
    },
    [executions, setSortedExecutions],
  );

  const columns = [
    {
      DataCell: (data) => (
        <>
          <span>{data.rowData.attributes?.x_decisiv_trace_id}</span>
          <span>
            <Button
              icon={Copy}
              style={{
                paddingBottom: '5px',
                paddingLeft: '5px',
                cursor: 'pointer',
              }}
              variant="ghost"
              onClick={() =>
                navigator.clipboard.writeText(
                  data?.rowData?.attributes?.x_decisiv_trace_id,
                )
              }
              size="small"
            />
          </span>
        </>
      ),
      title: t`X-DECISIV-TRACE-ID`,
      name: 'x_decisiv_trace_id',
    },
    {
      DataCell: (data) => {
        let text;
        let color;
        let icon;

        switch (data.rowData.attributes.status) {
          case 'SUCCEEDED':
            color = 'success';
            icon = CheckCircleF;
            text = t`Succeeded`;
            break;
          case 'ExecutionDoesNotExist':
            color = 'danger';
            icon = ExclamationTriangleF;
            text = t`Execution Does Not Exist`;
            break;
          case 'RUNNING':
            color = 'information';
            text = 'Processing';
            icon = Hourglass;
            break;
          case 'NOT_FOUND':
            color = 'success';
            text = 'Succeded - No Results';
            icon = CheckCircle;
            break;
          case 'FAILED':
          default:
            color = 'danger';
            icon = ExclamationTriangle;
            text = t`Failed`;
        }

        let outTag = null;
        if (
          data.rowData.attributes.status &&
          data.rowData.attributes.status !== 'RUNNING'
        ) {
          outTag = (
            <>
              <Flex alignItems="center">
                <Popover
                  placement="bottom-start"
                  manageEvents="hover"
                  target={({ ref, toggle }) => (
                    <Tag
                      ref={ref}
                      color={color}
                      icon={icon}
                      variant="outline"
                      action={toggle}
                      style={{ marginLeft: '15px' }}
                    />
                  )}
                >
                  <Flex padding={1}>
                    <span>{text}</span>
                  </Flex>
                </Popover>
              </Flex>
            </>
          );
        } else if (data.rowData.attributes.status === 'RUNNING') {
          outTag = (
            <>
              <Flex alignItems="center">
                <Popover
                  placement="bottom-start"
                  manageEvents="hover"
                  target={({ ref }) => (
                    <Button
                      icon={Hourglass}
                      ref={ref}
                      variant="ghost"
                      style={{ cursor: 'pointer', paddingLeft: '20px' }}
                    />
                  )}
                >
                  <Flex padding={1}>
                    <span>{text}</span>
                  </Flex>
                </Popover>
              </Flex>
            </>
          );
        }

        return outTag;
      },
      sortBy: onSort('attributes.status'),
      title: t`STATUS`,
      name: 'http_status',
    },
    {
      DataCell: (data) => (
        <>
          <span>
            {data.rowData.attributes.response_from_cache === true
              ? t`Cache`
              : t`Live`}
          </span>
        </>
      ),
      sortBy: onSort('attributes.response_from_cache'),
      title: t`SOURCE`,
      name: 'source',
    },
    {
      DataCell: (data) => (
        <>
          {data.rowData.attributes.trigger_type === 'http_api_request' ? (
            <>
              <span>Http</span>
            </>
          ) : (
            <>
              <span>Application Event</span>
            </>
          )}
        </>
      ),
      sortBy: onSort('attributes.trigger_type'),
      title: t`TRIGGER`,
      name: 'trigger',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.status ||
            data.rowData.attributes.start_date === 'ExecutionDoesNotExist' ? (
              <>
                <span>
                  {data.rowData.attributes.status &&
                    moment(data.rowData.attributes.start_date).format(
                      'MM/DD/YYYY hh:mm:ss a z',
                    )}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      sortBy: onSort('attributes.start_date'),
      title: t`STARTED`,
      name: 'started',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.status ||
            data.rowData.attributes.stop_date === 'ExecutionDoesNotExist' ? (
              <>
                <span>
                  {data.rowData.attributes.status &&
                    data.rowData.attributes.stop_date &&
                    moment(data.rowData.attributes.stop_date).format(
                      'MM/DD/YYYY hh:mm:ss a z',
                    )}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      sortBy: onSort('attributes.stop_date'),
      title: t`END TIME`,
      name: 'end_time',
    },
    {
      DataCell: (data) => {
        return (
          <>
            <Button
              icon={Newspaper}
              variant="ghost"
              onClick={() => handleShowModal(data)}
              id={`showLogModalBtn-${data.rowData.id}`}
              style={{ cursor: 'pointer' }}
            />
          </>
        );
      },
      name: 'actions',
    },
  ];

  return (
    <>
      <ExecutionDetailsModal
        log={selectedLogDetail}
        visibility={logModalDetailsVisibility}
        handleVisibility={setLogModalDetailsVisibility}
      />
      <Flex justifyContent="space-between">
        <Flex>
          <H3 marginBottom={1.8}>
            <Trans>Executions</Trans>
          </H3>
        </Flex>
        {groupedExecutions?.length > 0 && (
          <Flex>
            <Button
              icon={Refresh}
              text={t`Refresh`}
              kind="secondary"
              size="small"
              style={{ marginRight: '0.5rem' }}
              onClick={() => {
                setRefresh(true);
              }}
            />
            <Button
              icon={ExternalLink}
              text={t`See All`}
              kind="secondary"
              size="small"
              onClick={() =>
                window.open(
                  'https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/statemachines',
                  '_blank',
                )
              }
            />
          </Flex>
        )}
      </Flex>

      {groupedExecutions?.length > 0 && (
        <>
          {groupedExecutions?.map((_group, index) => (
            <Flex flexDirection="column" marginTop={1}>
              <P color="alaskanHusky" weight="semibold">
                Arn:
              </P>
              <Flex>
                <Flex
                  flexDirection="column"
                  justifyContent="flex-start"
                  flexWrap="nowrap"
                >
                  <P color="alaskanHusky">
                    {groupedExecutions[index][0].attributes.step_function_arn}
                  </P>
                </Flex>
              </Flex>
            </Flex>
          ))}
        </>
      )}

      <Flex flexGrow={1} flexDirection="column">
        {groupedExecutions?.length > 0 && (
          <Table
            columns={columns}
            data={currentPageExecutions}
            kind="secondary"
            getRowKey={({ id }) => id}
            footer={() => (
              <Flex flex={1} alignItems="center" justifyContent="space-between">
                <Flex>
                  <P>
                    {currentStartIndex + 1} -{' '}
                    {itemsPerPage > currentPageExecutions.length
                      ? executions.length
                      : currentEndIndex}{' '}
                    of {executions.length} Items
                  </P>
                </Flex>
                <Pagination
                  totalPages={totalExecutionsPage}
                  onPageChange={pageChangeHandler}
                  activePage={currentPage}
                />
              </Flex>
            )}
          />
        )}
      </Flex>
    </>
  );
}

LocationExecutionLog.defaultProps = {
  executionsList: [],
  setRefresh: noop,
};

LocationExecutionLog.propTypes = {
  executionsList: PropTypes.oneOfType([PropTypes.array]),
  setRefresh: PropTypes.func,
};
