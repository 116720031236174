import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { PropTypes } from 'prop-types';
import Pencil from '@decisiv/iconix/lib/components/Pencil';
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  H3,
  H4,
  Modal,
  P,
  TextArea,
  TextField,
  useNotifications,
} from '@decisiv/ui-components';
import { groupBy, isEmpty, noop, sortBy, startCase, trim } from 'lodash';
import { t, Trans } from '@lingui/macro';
import Archive from '@decisiv/iconix/lib/components/Archive';
import { navigate } from '@reach/router';
import CryptoJS from 'crypto-js';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';
import ChangeHistory from '../../components/ChangeHistory';
import WrapperWithLoading from '../../components/WrapperWithLoading';
import ConfirmationModal from './ConfirmationModal';
import EditMetadata from './EditMetadata';
import SchemaDetailsModal from '../../components/SchemaDetailsModal';
import CommandIconAndTitle from '../../components/CommandIconAndTitle';
import { buildSchemaContent } from '../../utils/buildSchemaContent';
import {
  FormDivider,
  FormGroup,
  IntegrationForm,
  SectionDivider,
  SectionRow,
} from './StyledComponents';
import IntegrationsService from '../../api/integrations';
import CommandsService from '../../api/commands';
import OverlayLoading from '../../components/OverlayLoading';
import { SETTINGS_PAGE_INPUT_PAYLOAD_TEXT } from '../../common/constants/staticTexts';
import {
  ArchiveBox,
  AudienceInfo,
  IntegrationsChangeHistoryContainer,
  IntegrationSettingsForm,
  IntegrationSettingsInfo,
  IntegrationWrapper,
} from './style';

const IntegrationSettings = (props) => {
  const TRIGGER_TYPE_HTTP_API_REQUEST = 'http_api_request';
  const [form, setForm] = useState({
    ...props?.integration?.attributes?.global_settings_attributes,
  });
  const [errors, setErrors] = useState({});
  const formEl = useRef(null);
  const [edit, setEdit] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [integration, setIntegration] = useState(props.integration);
  const [settingValues, setSettingValues] = useState({});
  const [initialAttrs, setInitialAttrs] = useState(
    JSON.parse(
      JSON.stringify(integration?.attributes?.global_settings_attributes),
    ),
  );
  const [metadata, setMetadata] = useState(
    isEmpty(integration.attributes.metadata)
      ? []
      : Object.entries(integration.attributes.metadata),
  );
  const [cacheEnabled, setCacheEnabled] = useState(
    integration.attributes.cache_enabled,
  );
  const [cacheTTL, setCacheTTL] = useState(
    JSON.stringify(integration.attributes.cache_ttl),
  );
  const [showTtlField, setShowTtlField] = useState(
    integration.attributes.cache_enabled,
  );
  const [audience, setAudience] = useState(
    props.integration.attributes.audience,
  );
  const [serviceNetwork, setServiceNetwork] = useState(
    props.integration.attributes.service_network,
  );
  const [action, setAction] = useState(props.integration.attributes.action);
  const [triggerTypes, setTriggerTypes] = useState(
    props.integration.attributes.trigger_type,
  );
  const [applicationEvents, setApplicationEvents] = useState(
    props.integration.attributes.events,
  );
  const [integrationEnabledStatus, setIntegrationEnabledStatus] = useState(
    props.integration?.attributes?.enabled,
  );
  const [integrationPublishedStatus, setIntegrationPublishedStatus] = useState(
    props.integration?.attributes?.published,
  );
  const [groupedStaticAttributes, setGroupedStaticAttributes] = useState([]);
  const [saving, setSaving] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [changeHistory, setChangeHistory] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingState, setLoadingState] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const handleToggleArchiveModal = () => setShowArchiveModal(!showArchiveModal);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [schemasByCommand, setSchemasByCommand] = useState([]);
  const [schemasContent, setSchemasContent] = useState({
    input: '',
    output: '',
  });
  const [schemaDetailsModalVisibility, setSchemaDetailsModalVisibility] =
    useState(false);
  const [showCacheSettings, setShowCacheSettings] = useState(false);

  const handleTogglePublishConfirmationModal = () =>
    setShowConfirmationModal(!showConfirmationModal);

  const handleShowTtlField = () => {
    setShowTtlField(!showTtlField);
  };

  useEffect(() => {
    async function getSchemasByCommand() {
      if (integration?.id) {
        const schemasGroupedByCommand =
          await CommandsService.fetchSchemasGroupedByCommand(
            integration.attributes.commands_flow,
          );
        setSchemasByCommand(schemasGroupedByCommand);
      }
    }

    getSchemasByCommand();
  }, [integration?.id, integration.attributes.commands_flow]);

  useEffect(() => {
    async function getData() {
      const unorderedStaticAttributes =
        props.integration.attributes.global_settings_attributes;

      const commandsFlow = props.integration.attributes.commands_flow;

      /* istanbul ignore else */
      if (Object.keys(unorderedStaticAttributes).length > 0) {
        let prevAttrIndex = '0';
        unorderedStaticAttributes.forEach((attribute, i) => {
          let attrIndex = attribute.name.match(/\d+/);
          if (attrIndex === null) {
            attrIndex = prevAttrIndex;
          }
          const commandIndex = (parseInt(attrIndex, 10) - 1) / 2;
          const commandName = commandsFlow[commandIndex];
          unorderedStaticAttributes[i].sort_index = commandIndex;
          unorderedStaticAttributes[
            i
          ].command_name = `${commandName}${attrIndex}`;
          prevAttrIndex = attrIndex;
        });

        const orderedStaticAttributes = sortBy(unorderedStaticAttributes, [
          'sort_index',
        ]);

        const settingsAttributes = {};
        orderedStaticAttributes.forEach((attr) => {
          settingsAttributes[attr.name] = attr.value;
        });

        setSettingValues(settingsAttributes);

        const currentGroupedStaticAttributes = groupBy(
          orderedStaticAttributes,
          'command_name',
        );

        setGroupedStaticAttributes(currentGroupedStaticAttributes);
      }

      setIntegration(props.integration);
      setAudience(props.integration.attributes.audience);
      setServiceNetwork(props.integration.attributes.service_network);
      setTriggerTypes(props.integration.attributes.trigger_type);
      if (
        props.integration.attributes.trigger_type.includes(
          TRIGGER_TYPE_HTTP_API_REQUEST,
        )
      ) {
        setShowCacheSettings(true);
      }
      setApplicationEvents(props.integration.attributes.events);
      setAction(props.integration.attributes.action);

      const fetchChangeHistory =
        await IntegrationsService.fetchIntegrationChangeHistory(
          integration?.id,
        );

      /* istanbul ignore else */
      if (fetchChangeHistory.data) {
        setChangeHistory(fetchChangeHistory.data);
        setLoading(false);
      }
    }

    if (props.integration.attributes.global_settings_attributes.length === 0) {
      setEdit(false);
    }

    getData();
  }, [integration?.id, props.integration]);

  const handleTextChange = (e) => {
    if (e.target.name.includes('cache_ttl')) {
      const value = e.target.value.replace(/\D/g, '');
      setCacheTTL(value);
    } else {
      const index =
        props.integration.attributes.global_settings_attributes.findIndex(
          (da) => da.name === e.target.name,
        );

      const newForm = form;
      newForm[index].value = e.target.value;
      setForm(newForm);
      setSettingValues({ ...settingValues, [e.target.name]: e.target.value });
    }
  };

  const handleCheckboxChange = (e) => {
    const index =
      props.integration.attributes.global_settings_attributes.findIndex(
        (da) => da.name === e.target.name,
      );

    const newForm = form;

    newForm[index].value = e.target.checked.toString();
    setForm(newForm);
    setSettingValues({
      ...settingValues,
      [e.target.name]: e.target.checked.toString(),
    });
  };

  const metadataHandlers = useMemo(() => {
    return {
      addEntry: () => {
        setMetadata(metadata.concat([['', '']]));
      },
      changeValue: ({ e, item, index }) => {
        const { value } = e.target;

        setMetadata((previousMetadata) => {
          const newMetadata = [...previousMetadata];
          newMetadata[index] = [item[0], value];

          return newMetadata;
        });
      },
      changeKey: ({ e, item, index }) => {
        const { value } = e.target;

        setMetadata((previousMetadata) => {
          const newMetadata = [...previousMetadata];
          newMetadata[index] = [value, item[1]];

          return newMetadata;
        });
      },
      removeEntry: (index) => {
        setMetadata((previousMetadata) => {
          const newMetadata = [...previousMetadata];

          newMetadata.splice(index, 1);
          return newMetadata;
        });
      },
    };
  }, [metadata]);

  const validateField = (e) => {
    if (e.target.required && isEmpty(trim(e.target.value))) {
      setErrors({ ...errors, [e.target.name]: t`Can't be blank` });
      setDisableSubmit(true);
    } else {
      setErrors({ ...errors, [e.target.name]: '' });
      setDisableSubmit(false);
    }
  };

  const resetData = () => {
    const initialMetadata = isEmpty(integration.attributes.metadata)
      ? []
      : Object.entries(integration.attributes.metadata);

    const initialSettingValues = {};
    initialAttrs.forEach((attr) => {
      initialSettingValues[attr.name] = attr.value;
    });

    setMetadata(initialMetadata);
    setCacheEnabled(integration.attributes.cache_enabled);
    setShowTtlField(integration.attributes.cache_enabled);
    setCacheTTL(JSON.stringify(integration.attributes.cache_ttl));
    setSettingValues(initialSettingValues);
  };

  const toggleEdit = () => {
    if (!edit) {
      resetData();
    }

    setEdit(!edit);
    setDisableSubmit(!edit);
  };

  const notificationsProps = {
    intent: 'success',
    title: `Updated Integration Settings`,
    onClose: noop,
  };

  const { notify } = useNotifications();

  const initSettings = (data) => {
    if (data) {
      setIntegration(data);
      setInitialAttrs(
        JSON.parse(
          JSON.stringify(integration?.attributes?.global_settings_attributes),
        ),
      );
    }
  };

  const handleSubmit = async () => {
    setDisableSubmit(true);
    setSaving(true);

    const metadataToObject = (metadataInputs) => {
      const metadataObject = Object.fromEntries(metadataInputs);
      Object.keys(metadataObject).forEach(
        (k) => isEmpty(k) && delete metadataObject[k],
      );

      return metadataObject;
    };

    const attributesWithObject = Object.keys(form).map((number) => {
      try {
        return {
          name: form[number].name,
          value:
            form[number].value.includes('{') || form[number].value.includes('[')
              ? JSON.parse(form[number].value)
              : form[number].value,
        };
      } catch (e) {
        return {
          name: form[number].name,
          value: form[number].value,
        };
      }
    });

    let data;

    try {
      data = await IntegrationsService.updateIntegrationStaticAttributes(
        integration.id,
        attributesWithObject,
        metadataToObject(metadata),
        cacheEnabled,
        cacheEnabled ? cacheTTL : 0,
      );
    } catch (e) {
      data = false;
    }

    if (data) {
      initSettings(data.data);
      const successMessageBody = `${integration.attributes.name} was successfully updated.`;
      setEdit(true);
      notificationsProps.intent = 'success';
      notify(notificationsProps, successMessageBody);
    } else {
      const failureMessage = `Failed to update ${integration.attributes.name}.`;
      notificationsProps.intent = 'warning';
      notify(notificationsProps, failureMessage);
      setDisableSubmit(false);
    }

    setSaving(false);
  };

  const setInputLabel = (name) => {
    const inputWithoutNumber = String(name).replace(/[0-9]/g, '');

    if (
      Object.keys(
        props.integration.attributes.global_settings_attributes,
      ).filter((v) =>
        props.integration.attributes.global_settings_attributes[
          v
        ].name.includes(inputWithoutNumber),
      ).length > 1
    ) {
      return name;
    }

    return inputWithoutNumber;
  };

  /* istanbul ignore next */
  const transformObject = (inputProps) => {
    const objectKeys = Object.keys(inputProps.defaultValue);
    const currentValue =
      objectKeys.length > 0
        ? `{\n${objectKeys
            .map(
              (e) =>
                `${JSON.stringify(e)}: ${
                  typeof inputProps.defaultValue[e] === 'boolean'
                    ? inputProps.defaultValue[e]
                    : JSON.stringify(inputProps.defaultValue[e])
                }`,
            )
            .join(',\n')}\n}`
        : '{\n}';

    return currentValue;
  };

  /* istanbul ignore next */
  const transformArray = (inputProps) => {
    return `[\n${inputProps.defaultValue.map((e) => `"${e}"`).join(',\n')}\n]`;
  };

  /* istanbul ignore next */
  function getCurrentValue(inputProps) {
    let currentValue = '';

    switch (inputProps.defaultValue?.constructor) {
      case Object:
        currentValue = transformObject(inputProps);
        break;
      case Array:
        currentValue = transformArray(inputProps);
        break;
      default:
        currentValue = inputProps.defaultValue;
        break;
    }

    return currentValue;
  }

  const decideFormElement = (input) => {
    const { name: formId } = input;

    const defaultValueIndex =
      integration.attributes.global_settings_attributes.findIndex(
        (da) => da.name === formId,
      );

    let defaultValue = '';

    /* istanbul ignore next */
    if (integration.attributes.global_settings_attributes[defaultValueIndex]) {
      defaultValue =
        integration.attributes.global_settings_attributes[defaultValueIndex]
          .value;
    }

    const inputProps = {
      name: formId,
      label: setInputLabel(formId),
      key: formId,
      onChange: handleTextChange,
      onBlur: validateField,
      disabled: edit,
      required: input.required,
      helpMessage: input.description,
      warningMessage: errors[input.name],
      value: settingValues[formId],
      defaultValue,
    };

    /* istanbul ignore next */
    if (input.html_tag === 'text_input') {
      return <TextField {...inputProps} />;
    }

    /* istanbul ignore next */
    if (input.html_tag === 'password_input') {
      inputProps.type = 'text';

      const ciphertext = isEmpty(inputProps.value)
        ? ''
        : CryptoJS.AES.encrypt(
            inputProps.value,
            process.env.REACT_APP_ENCRYPTION_SECRET_KEY,
          ).toString();

      // Decrypt
      const bytes = CryptoJS.AES.decrypt(
        ciphertext,
        process.env.REACT_APP_ENCRYPTION_SECRET_KEY,
      );
      const originalText = bytes.toString(CryptoJS.enc.Utf8);

      return edit ? (
        <TextField
          {...inputProps}
          defaultValue={ciphertext}
          value={ciphertext}
        />
      ) : (
        <TextField {...inputProps} value={originalText} />
      );
    }

    /* istanbul ignore next */
    if (input.html_tag === 'checkbox_input') {
      inputProps.checked = inputProps.value === 'true';
      inputProps.label = setInputLabel(startCase(input.name));
      inputProps.onChange = handleCheckboxChange;
      return <Checkbox {...inputProps} />;
    }

    /* istanbul ignore next */
    if (input.html_tag === 'object_input' || input.html_tag === 'text_area') {
      const currentValue = getCurrentValue(inputProps);

      return <TextArea {...inputProps} value={currentValue} />;
    }

    return '';
  };

  const integrationAction = props.integration?.attributes?.enabled
    ? t`DEACTIVATE`
    : t`ACTIVATE`;

  const integrationPublishedLabel = props.integration?.attributes?.published
    ? t`UNPUBLISH`
    : t`PUBLISH`;

  const publishModalDescription = props.integration?.attributes?.published
    ? t`Published integrations are publicly available for end users to enable and self manage.`
    : t`Unpublished integrations are private integrations that can only be managed by Decisiv Global Admins.`;

  const notificationSuccessProps = {
    intent: 'success',
    title: `Configured Global Settings`,
    duration: 3500,
    onClose: noop,
  };
  const notificationErrorProps = {
    intent: 'danger',
    title: `Something went wrong!`,
    duration: 3500,
    onClose: noop,
  };

  const toggleIntegrationStatus = async (integrationField) => {
    setLoadingState(true);

    let value = '';
    switch (integrationField) {
      case 'enabled':
        value = integrationEnabledStatus;
        break;
      case 'published':
        value = integrationPublishedStatus;
        break;
      default:
        break;
    }

    const response = await IntegrationsService.toggleIntegration(
      integration.id,
      !value,
      integrationField,
    );
    setLoadingState(false);

    if (response.data) {
      props.updateIntegrationStatus(!value, integrationField);
      switch (integrationField) {
        case 'enabled':
          setIntegrationEnabledStatus(!value);

          notify(
            {
              ...notificationSuccessProps,
              title: `Integration ${integrationAction}D`,
            },
            `${
              integration.attributes.name
            } has been ${integrationAction.toLocaleLowerCase()}d.`,
          );

          break;
        case 'published':
          setIntegrationPublishedStatus(!value);

          notify(
            {
              ...notificationSuccessProps,
              title: `Integration ${integrationPublishedLabel}ED`,
            },
            `${
              integration.attributes.name
            } has been ${integrationPublishedLabel.toLocaleLowerCase()}ed.`,
          );

          break;
        default:
          break;
      }
    } else {
      notify(notificationErrorProps, response?.errors[0].detail);
    }
  };

  const integrationConfigured = useCallback(() => {
    return Object.values(form)
      .filter((value) => value.required)
      .every((value) => !isEmpty(trim(value.value)));
  }, [form]);

  const archiveIntegration = async () => {
    setArchiving(true);

    const response = await IntegrationsService.archiveIntegration(
      integration?.id,
    );

    if (response.status === 204) {
      navigate(`/integrations`);
      notify(
        {
          ...notificationSuccessProps,
          title: t`Integration Archived`,
        },
        t`${integration.attributes.name} has been archived`,
      );
    } else {
      navigate(`/integrations`);
      notify(
        {
          ...notificationErrorProps,
          title: t`Something went wrong!`,
        },
        t`Attempt to archive ${integration.attributes.name} failed.`,
      );
    }

    setArchiving(false);
  };

  const handleEditIntegration = () => {
    navigate(`/integrations/${integration?.id}/edit`);
  };

  const handleSchemaDetailsModalOpen = (commandName) => {
    const content = buildSchemaContent(
      commandName,
      schemasByCommand,
      integration.attributes.schemas,
    );
    setSchemasContent(content);
    setSchemaDetailsModalVisibility(true);
  };

  return (
    <>
      <ConfirmationModal
        modalVisibility={showConfirmationModal}
        toggleModal={setShowConfirmationModal}
        changeIntegrationStatus={toggleIntegrationStatus}
        modalAction={integrationPublishedLabel}
        integrationName={integration.attributes.name}
        description={publishModalDescription}
      />
      <SchemaDetailsModal
        content={schemasContent}
        visibility={schemaDetailsModalVisibility}
        handleVisibility={setSchemaDetailsModalVisibility}
      />
      {archiving ? (
        <>
          <Modal title="" visible={showArchiveModal}>
            <WrapperWithLoading
              loading={archiving}
              description="Archiving Integration"
            />
          </Modal>
        </>
      ) : (
        <Modal
          actions={[
            {
              text: t`Archive Integration`,
              onClick: archiveIntegration,
              intent: 'danger',
            },
            { text: t`Cancel`, onClick: handleToggleArchiveModal },
          ]}
          icon={Archive}
          color="danger"
          onClose={handleToggleArchiveModal}
          title={t`Archive Integration`}
          visible={showArchiveModal}
        >
          <P color="alaskanHusky" weight="medium" marginTop={1}>
            <Trans>
              After clicking ARCHIVE INTEGRATION this integration will be put in
              the archive and no longer active. You can restore it from the
              archive page. Any locations in this integration will be disabled.
            </Trans>
          </P>
        </Modal>
      )}
      <IntegrationWrapper>
        <Flex marginX={1}>
          <Grid.Container>
            <Grid.Row>
              <Grid.Column span="3">
                <IntegrationSettingsInfo>
                  <H3>
                    {integration.attributes.name} {t`Integration`}
                  </H3>
                  <Flex>
                    <Trans>Category</Trans>
                    <P color="alaskanHusky" weight="medium" size="small">
                      &nbsp;{integration.attributes.category}
                    </P>
                  </Flex>
                  <Flex flexDirection="column">
                    <Flex marginBottom={1}>
                      {!integrationEnabledStatus && (
                        <P color="alaskanHusky" weight="medium" size="small">
                          <Trans>
                            Once activated, it will become available for all
                            enabled locations.
                          </Trans>
                        </P>
                      )}
                    </Flex>
                    <Button
                      loading={loadingState}
                      margin="10px"
                      text={integrationAction}
                      disabled={!integrationConfigured()}
                      onClick={() => toggleIntegrationStatus('enabled')}
                    />
                    <Flex marginTop={1}>
                      <Button
                        loading={loadingState}
                        text={integrationPublishedLabel}
                        disabled={!integrationConfigured()}
                        style={{ width: '100%' }}
                        onClick={handleTogglePublishConfirmationModal}
                      />
                    </Flex>
                    <SectionDivider />
                    <Flex marginTop={1} marginBottom={1}>
                      <P color="alaskanHusky" weight="medium" size="small">
                        <Trans>
                          Editing an Integration could lead to changes required
                          in the global settings.
                        </Trans>
                      </P>
                    </Flex>
                    <Button
                      margin="10px"
                      text={t`Edit Integration`}
                      kind="secondary"
                      size="small"
                      onClick={handleEditIntegration}
                    />
                  </Flex>
                </IntegrationSettingsInfo>

                <AudienceInfo>
                  <H3>
                    <Trans>Audience</Trans>
                  </H3>
                  {audience?.sort()?.map((audienceInput) => (
                    <p key={audienceInput}>{audienceInput}</p>
                  ))}
                </AudienceInfo>

                <AudienceInfo>
                  <H3>
                    <Trans>Service Network</Trans>
                  </H3>
                  {serviceNetwork?.sort()?.map((serviceNetworkInput) => (
                    <p key={serviceNetworkInput}>{serviceNetworkInput}</p>
                  ))}
                </AudienceInfo>

                <AudienceInfo>
                  <H3>
                    <Trans>Trigger</Trans>
                  </H3>
                  {triggerTypes?.sort()?.map((trigger) => (
                    <p key={trigger}>{trigger}</p>
                  ))}{' '}
                </AudienceInfo>

                {action && (
                  <AudienceInfo>
                    <H3>
                      <Trans>Action</Trans>
                    </H3>
                    <p key={action}>{action}</p>
                  </AudienceInfo>
                )}

                {applicationEvents?.length > 0 && (
                  <AudienceInfo>
                    <H3>
                      <Trans>Application Events</Trans>
                    </H3>
                    {applicationEvents?.sort()?.map((event) => (
                      <p key={event}>{event}</p>
                    ))}{' '}
                  </AudienceInfo>
                )}

                <ArchiveBox margimTop={1}>
                  <Flex flexDirection="column">
                    <P
                      color="alaskanHusky"
                      weight="medium"
                      size="small"
                      marginBottom={1}
                    >
                      <Trans>
                        Archived integrations are no longer visible from the
                        homepage.
                      </Trans>
                    </P>
                    <Button
                      margin="10px"
                      text={t`Archive`}
                      name={t`archive`}
                      kind="secondary"
                      size="small"
                      onClick={handleToggleArchiveModal}
                    />
                  </Flex>
                </ArchiveBox>
              </Grid.Column>
              <Grid.Column span="9">
                <IntegrationSettingsForm ref={formEl}>
                  {saving && (
                    <OverlayLoading loading={saving} message={t`Saving...`} />
                  )}
                  <Grid.Container>
                    <Grid.Row>
                      <Grid.Column>
                        <WrapperWithLoading loading={loading}>
                          <IntegrationForm>
                            <Grid.Container>
                              <SectionRow>
                                <Grid.Column span="10">
                                  <Flex>
                                    <Flex flexDirection="column">
                                      <H3>{t`Global Settings`}</H3>
                                      <P shade={1} size="small">
                                        <Trans>
                                          Configure the global settings for
                                        </Trans>
                                        &nbsp;&lt;{integration.attributes.name}
                                        &gt;&nbsp;
                                        <Trans>integration.</Trans>
                                      </P>
                                    </Flex>
                                  </Flex>
                                </Grid.Column>
                                {edit && (
                                  <Grid.Column span="2">
                                    <Flex justifyContent="right">
                                      <Button
                                        icon={Pencil}
                                        text={t`Edit`}
                                        name={t`edit`}
                                        kind="secondary"
                                        onClick={toggleEdit}
                                      />
                                    </Flex>
                                  </Grid.Column>
                                )}
                              </SectionRow>
                              <SectionRow key="InputPayload">
                                <Grid.Column span="2">
                                  <CommandIconAndTitle
                                    title={t`InputPayload`}
                                    onClick={() =>
                                      handleSchemaDetailsModalOpen(
                                        t`InputPayload`,
                                      )
                                    }
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <P shade={1} style={{ marginTop: 7 }}>
                                    <Trans>
                                      {SETTINGS_PAGE_INPUT_PAYLOAD_TEXT}
                                    </Trans>
                                  </P>
                                </Grid.Column>
                              </SectionRow>
                              {Object.keys(groupedStaticAttributes).map(
                                (classification) => (
                                  <SectionRow key={classification}>
                                    <Grid.Column span="2">
                                      <CommandIconAndTitle
                                        title={classification}
                                        onClick={() =>
                                          handleSchemaDetailsModalOpen(
                                            classification,
                                          )
                                        }
                                      />
                                    </Grid.Column>
                                    <Grid.Column
                                      key={`Column${classification}`}
                                    >
                                      {Object.keys(
                                        groupedStaticAttributes[classification],
                                      ).map((input) => (
                                        <FormGroup
                                          key={`FormGroup${
                                            classification + input
                                          }`}
                                        >
                                          {decideFormElement(
                                            groupedStaticAttributes[
                                              classification
                                            ][input],
                                          )}
                                        </FormGroup>
                                      ))}
                                    </Grid.Column>
                                  </SectionRow>
                                ),
                              )}
                              <FormDivider />
                              <SectionRow>
                                <Grid.Column span="2">
                                  <H4>
                                    <Trans>Metadata</Trans>
                                  </H4>
                                </Grid.Column>
                                <Grid.Column>
                                  <FormGroup key="metadata">
                                    <EditMetadata
                                      metadata={metadata}
                                      handlers={metadataHandlers}
                                      editDisabled={edit}
                                    />
                                  </FormGroup>
                                </Grid.Column>
                              </SectionRow>
                              <FormDivider />
                              {showCacheSettings && (
                                <SectionRow>
                                  <Grid.Column span="2">
                                    <H4>
                                      <Trans>Cache</Trans>
                                    </H4>
                                  </Grid.Column>
                                  <Grid.Column>
                                    {edit && (
                                      <FormGroup key="cache">
                                        {cacheEnabled === true ? (
                                          <Grid.Column>
                                            <span className="spanDetail">
                                              <P>Enabled</P>
                                            </span>
                                            <span>
                                              <P>Yes</P>
                                            </span>
                                            <span className="spanDetail">
                                              <P>Cache TTL</P>
                                            </span>
                                            <span>
                                              <P>{cacheTTL} seconds</P>
                                            </span>
                                          </Grid.Column>
                                        ) : (
                                          <Grid.Column>
                                            <span className="spanDetail">
                                              <P>Enabled</P>
                                            </span>
                                            <span>
                                              <P>No</P>
                                            </span>
                                          </Grid.Column>
                                        )}
                                      </FormGroup>
                                    )}
                                    {!edit && (
                                      <FormGroup key="cacheEnabled">
                                        <RadioGroup
                                          name="cache_enabled"
                                          size="medium"
                                          items={[
                                            { label: t`Yes`, value: true },
                                            { label: t`No`, value: false },
                                          ]}
                                          onChange={(e) => {
                                            let convertedValue = false;
                                            convertedValue =
                                              e.target.value === 'true';
                                            setCacheEnabled(convertedValue);
                                            handleShowTtlField();
                                          }}
                                          defaultValue={cacheEnabled === true}
                                          onClick={(event) => {
                                            event.stopPropagation();
                                          }}
                                        />
                                        <br />
                                        {showTtlField ? (
                                          <TextArea
                                            label={t`Cache TTL in seconds`}
                                            name={t`cache_ttl`}
                                            required
                                            type="number"
                                            value={cacheTTL}
                                            onChange={handleTextChange}
                                            maxLength={6}
                                          />
                                        ) : null}
                                      </FormGroup>
                                    )}
                                    {!edit && (
                                      <SectionRow>
                                        <Flex marginTop={2}>
                                          <Button
                                            name={t`save`}
                                            text={t`Save`}
                                            disabled={disableSubmit}
                                            onClick={handleSubmit}
                                          />
                                          <Button
                                            style={{ marginLeft: '0.4rem' }}
                                            name={t`cancel`}
                                            text={t`Cancel`}
                                            disabled={disableSubmit}
                                            kind="secondary"
                                            onClick={toggleEdit}
                                          />
                                        </Flex>
                                      </SectionRow>
                                    )}
                                  </Grid.Column>
                                </SectionRow>
                              )}
                            </Grid.Container>
                          </IntegrationForm>
                        </WrapperWithLoading>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid.Container>
                </IntegrationSettingsForm>
                <IntegrationsChangeHistoryContainer>
                  <Grid.Container>
                    <WrapperWithLoading loading={loading}>
                      <ChangeHistory
                        histories={changeHistory}
                        integration={integration}
                      />
                    </WrapperWithLoading>
                  </Grid.Container>
                </IntegrationsChangeHistoryContainer>
              </Grid.Column>
            </Grid.Row>
          </Grid.Container>
        </Flex>
      </IntegrationWrapper>
    </>
  );
};

export default IntegrationSettings;

IntegrationSettings.propTypes = {
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateIntegrationStatus: PropTypes.func.isRequired,
};
