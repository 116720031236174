import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import CheckCircleF from '@decisiv/iconix/lib/components/CheckCircleF';
import CheckCircle from '@decisiv/iconix/lib/components/CheckCircle';
import ExclamationTriangle from '@decisiv/iconix/lib/components/ExclamationTriangle';
import ExclamationTriangleF from '@decisiv/iconix/lib/components/ExclamationTriangleF';
import Hourglass from '@decisiv/iconix/lib/components/Hourglass';

import {
  Button,
  Flex,
  H3,
  P,
  Pagination,
  Popover,
  Table,
  Tag,
} from '@decisiv/ui-components';
import moment from 'moment/moment';
import Newspaper from '@decisiv/iconix/lib/components/Newspaper';
import Refresh from '@decisiv/iconix/lib/components/Refresh';
import Copy from '@decisiv/iconix/lib/components/Copy';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import isEmpty from 'lodash/isEmpty';
import ExecutionDetailsModal from '../../components/ExecutionDetails/ExecutionDetailsModal';
import { IntegrationDetailLocations } from './style';
import WrapperWithLoading from '../../components/WrapperWithLoading';

import LogInspectorService from '../../api/step_function_executions';
import EditMetadata from './EditMetadata';

export default function ExecutionsList({ integration }) {
  const itemsPerPage = 25;

  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [currentEndIndex, setCurrentEndIndex] = useState(0);
  const [executions, setExecutions] = useState([]);
  const [totalExecutionsPage, setTotalExecutionsPage] = useState(0);
  const [currentPageExecutions, setCurrentPageExecutions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedExecution, setSelectedExecution] = useState();
  const [executionModalDetailsVisibility, setExecutionModalDetailsVisibility] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [sortedExecutions, setSortedExecutions] = useState([]);

  const pageChangeHandler = (newPageNumber) => {
    setCurrentPage(newPageNumber);

    let currentExecutions = [];

    if (isEmpty(sortedExecutions)) {
      currentExecutions = executions;
    } else {
      currentExecutions = sortedExecutions;
    }

    // Calculate the starting index and ending index for the specified page
    const startIndex = (newPageNumber - 1) * itemsPerPage;
    setCurrentStartIndex(startIndex);
    const endIndex = startIndex + itemsPerPage;
    setCurrentEndIndex(endIndex);
    // Use slice to get the portion of the array for the specified page
    const pageExecutions = currentExecutions?.slice(startIndex, endIndex);
    setTotalExecutionsPage(Math.ceil(currentExecutions?.length / itemsPerPage));

    setCurrentPageExecutions(pageExecutions);
  };

  const onSort = useCallback(
    (columnName) => (sortingType) => {
      switch (sortingType) {
        case 'ascending':
          setSortedExecutions(sortBy(executions, [columnName]));
          return;
        case 'descending':
          setSortedExecutions(reverse(sortBy(executions, [columnName])));
          return;
        case 'unsorted':
        default:
          setSortedExecutions(executions);
      }
    },
    [executions, setSortedExecutions],
  );

  async function getExecutions() {
    setLoading(true);
    const responseLogs =
      await LogInspectorService.fetchStepFunctionExecutionsByIntegrationId(
        integration.id,
      );
    if (responseLogs.data && responseLogs.data.length > 0) {
      setExecutions(responseLogs.data);
      const pageExecutions = responseLogs.data?.slice(0, 25);
      setCurrentPageExecutions(pageExecutions);
    }
    setLoading(false);
  }

  useEffect(() => {
    setSortedExecutions(executions);
  }, [executions]);

  useEffect(() => {
    pageChangeHandler(1);
  }, [sortedExecutions]);

  useEffect(() => {
    getExecutions();
  }, []);

  const handleShowModal = (data) => {
    setSelectedExecution(data.rowData.attributes);
    setExecutionModalDetailsVisibility(true);
  };

  const columns = [
    {
      DataCell: (data) => (
        <Flex>
          <span>{data?.rowData?.attributes?.x_decisiv_trace_id}</span>
          <span>
            <Flex alignItems="center">
              <Popover
                placement="bottom-start"
                manageEvents="hover"
                target={({ ref, toggle }) => (
                  <Button
                    ref={ref}
                    action={toggle}
                    icon={Copy}
                    style={{ paddingBottom: '5px', paddingLeft: '5px' }}
                    variant="ghost"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        data?.rowData?.attributes?.x_decisiv_trace_id,
                      )
                    }
                    size="small"
                  />
                )}
              >
                <Flex padding={1}>
                  <span>{t`Copy Id`}</span>
                </Flex>
              </Popover>
            </Flex>
          </span>
        </Flex>
      ),
      title: t`X-DECISIV-TRACE-ID`,
      name: 'x_decisiv_trace_id',
    },
    {
      DataCell: (data) => (
        <a
          target="_blank"
          href={`/location_settings/${data?.rowData?.attributes?.configured_integration_id}`}
          rel="noreferrer"
        >
          {data?.rowData?.attributes?.location_name}
        </a>
      ),
      title: t`LOCATION`,
      sortBy: onSort('attributes.location_name'),
      name: 'location_name',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.city && data.rowData.attributes.state ? (
              <>
                <span>
                  {data.rowData.attributes.city},{' '}
                  {data.rowData.attributes.state}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      sortBy: onSort('attributes.city'),
      title: 'CITY/STATE',
      name: 'city',
    },
    {
      DataCell: (data) => {
        let text;
        let color;
        let icon;

        switch (data.rowData.attributes.status) {
          case 'SUCCEEDED':
            color = 'success';
            text = 'Succeeded';
            icon = CheckCircleF;
            break;
          case 'ExecutionDoesNotExist':
            color = 'danger';
            text = 'Execution Does Not Exist';
            icon = ExclamationTriangleF;
            break;
          case 'RUNNING':
            color = 'information';
            text = 'Processing';
            icon = Hourglass;
            break;
          case 'NOT_FOUND':
            color = 'success';
            text = 'Succeded - No Results';
            icon = CheckCircle;
            break;
          case 'FAILED':
          default:
            color = 'danger';
            text = 'Failed';
            icon = ExclamationTriangle;
        }

        let outTag = null;
        if (
          data.rowData.attributes.status &&
          data.rowData.attributes.status !== 'RUNNING'
        ) {
          outTag = (
            <>
              <Flex alignItems="center">
                <Popover
                  placement="bottom-start"
                  manageEvents="hover"
                  target={({ ref, toggle }) => (
                    <Tag
                      ref={ref}
                      color={color}
                      icon={icon}
                      variant="outline"
                      action={toggle}
                      style={{ marginLeft: '15px' }}
                    />
                  )}
                >
                  <Flex padding={1}>
                    <span>{text}</span>
                  </Flex>
                </Popover>
              </Flex>
            </>
          );
        } else if (data.rowData.attributes.status === 'RUNNING') {
          outTag = (
            <>
              <Flex alignItems="center">
                <Popover
                  placement="bottom-start"
                  manageEvents="hover"
                  target={({ ref }) => (
                    <Button
                      icon={Hourglass}
                      ref={ref}
                      variant="ghost"
                      style={{ cursor: 'pointer', marginLeft: '20px' }}
                    />
                  )}
                >
                  <Flex padding={1}>
                    <span>{text}</span>
                  </Flex>
                </Popover>
              </Flex>
            </>
          );
        }

        return outTag;
      },
      title: t`STATUS`,
      sortBy: onSort('attributes.status'),
      name: 'http_status',
    },
    {
      DataCell: (data) => (
        <>
          <span>
            {data.rowData.attributes.response_from_cache === true
              ? t`Cache`
              : t`Live`}
          </span>
        </>
      ),
      sortBy: onSort('attributes.response_from_cache'),
      title: t`SOURCE`,
      name: 'source',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.trigger_type === 'http_api_request' ? (
              <>
                <span>Http</span>
              </>
            ) : (
              <>
                <span>Application Event</span>
              </>
            )}
          </>
        );
      },
      title: t`Trigger`,
      sortBy: onSort('attributes.trigger_type'),
      name: 'trigger_type',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.status ||
            data.rowData.attributes.start_date === 'ExecutionDoesNotExist' ? (
              <>
                <span>
                  {data.rowData.attributes.status &&
                    moment(data.rowData.attributes.start_date).format(
                      'MM/DD/YYYY hh:mm:ss a z',
                    )}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      title: t`STARTED`,
      sortBy: onSort('attributes.start_date'),
      name: 'started',
    },
    {
      DataCell: (data) => {
        return (
          <>
            {data.rowData.attributes.status ||
            data.rowData.attributes.stop_date === 'ExecutionDoesNotExist' ? (
              <>
                <span>
                  {data.rowData.attributes.status &&
                    data.rowData.attributes.stop_date &&
                    moment(data.rowData.attributes.stop_date).format(
                      'MM/DD/YYYY hh:mm:ss a z',
                    )}
                </span>
              </>
            ) : (
              '⁠—'
            )}
          </>
        );
      },
      title: t`END TIME`,
      sortBy: onSort('attributes.stop_date'),
      name: 'end_time',
    },
    {
      DataCell: (data) => {
        return (
          <>
            <Button
              icon={Newspaper}
              variant="ghost"
              onClick={() => handleShowModal(data)}
              id={`showLogModalBtn-${data.rowData.id}`}
              style={{ cursor: 'pointer' }}
            />
          </>
        );
      },
      name: 'actions',
    },
  ];

  return (
    <IntegrationDetailLocations
      textAlign="center"
      name="integrationDetailLocations"
    >
      <ExecutionDetailsModal
        log={selectedExecution}
        visibility={executionModalDetailsVisibility}
        handleVisibility={setExecutionModalDetailsVisibility}
        modalTitle={t`Execution Details`}
      />

      <Flex flexDirection="column" name="locationResult" flexGrow={1}>
        <Flex name="searchBar">
          <Flex
            marginTop={2}
            marginLeft={2}
            marginRight={2}
            name="topBar"
            flexGrow={1}
          >
            <Flex name="searchBox">
              <Flex>
                <H3 marginRight={2} marginTop={0.2}>
                  <Trans>Executions</Trans>
                </H3>
              </Flex>
            </Flex>
            <Flex
              flexDirection="row"
              name="filters"
              marginLeft={1.9}
              marginBottom={5}
            />
            <Flex justifyContent="flex-end" flexGrow={1}>
              <Button
                icon={Refresh}
                text={t`Refresh`}
                size="small"
                kind="secondary"
                onClick={getExecutions}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex name="searchResult">
          <WrapperWithLoading loading={loading}>
            <Flex flexGrow={1} flexDirection="column">
              <Table
                columns={columns}
                data={currentPageExecutions}
                kind="secondary"
                getRowKey={({ id }) => id}
                footer={() => (
                  <Flex
                    flex={1}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex>
                      <P>
                        {currentStartIndex + 1} -{' '}
                        {itemsPerPage > executions.length
                          ? executions.length
                          : currentEndIndex}{' '}
                        of {executions.length} Items
                      </P>
                    </Flex>
                    <Pagination
                      totalPages={totalExecutionsPage}
                      onPageChange={pageChangeHandler}
                      activePage={currentPage}
                    />
                  </Flex>
                )}
              />
            </Flex>
          </WrapperWithLoading>
        </Flex>
      </Flex>
    </IntegrationDetailLocations>
  );
}

ExecutionsList.propTypes = {
  integration: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

EditMetadata.defaultProps = {
  integration: null,
};
